<template>
  <div class="container">
    <el-row class="sale-module-query-search flex flex-align-center">
      <div
        class="select-wrap flex flex-align-center"
        @click="$router.push('/sale/mall/home')"
      >
        <el-button type="text" icon="el-icon-arrow-left"
          >设计师合作款</el-button
        >
      </div>
      <div class="select-wrap flex flex-align-center">
        <label>风格</label>
        <el-select
          v-model="queryOpt.where.styleIds"
          :popper-append-to-body="false"
          placeholder="全部"
          multiple
          size="small"
          clearable
        >
          <el-option
            v-for="item in config.styles"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select-wrap flex-1 flex">
        <div class="flex-1"></div>
        <div class="head flex">
          <div class="flex flex-align-center">
            <span
              @click="sort('time')"
              :class="{ active: opt.sort_by != 'price' }"
              >按时间排序</span
            >
            <img
              @click="sort('up')"
              v-if="opt.sort_by != 'price' && opt.sort_order != 1"
              src="@/assets/images/factory/sort_down.png"
            />
            <img
              @click="sort('down')"
              v-if="opt.sort_by != 'price' && opt.sort_order == 1"
              src="@/assets/images/factory/sort_up.png"
            />
          </div>
        </div>
      </div>
    </el-row>
    <div class="list" ref="load">
      <div v-if="list && list.length > 0" class="flex">
        <div
          v-for="item in list"
          :key="item.id"
          class="item-block"
          @click="toDetail(item.id)"
        >
          <div>
            <img :src="imgUrl + item.photoRender[0] + '!width_250px'" />
            <div v-if="item.name" class="name">{{ item.name }}</div>
            <div class="feature">
              <span
                style="margin-right: 4px"
                v-if="item.style && item.style.name"
                >{{ item.style.name }} |</span
              >
              <span style="margin-right: 4px" v-if="item.cat && item.cat.name"
                >{{ item.cat.name }} |</span
              >
              {{ item.series && item.series.name }}
            </div>
            <div class="price">
              ¥{{ item.startPrice }}
              <span>起</span>
            </div>
            <!-- <div v-if="item.statistics" class="watch-count">已有{{ item.statistics.uv }}人查看</div> -->
            <div class="label">
              <label class="color-1">现货</label>
              <label class="color-2">高信用</label>
              <label class="color-3">低价</label>
            </div>
          </div>

          <div class="comp-check">
            <div class="count">{{ item.cooperatedCount }}</div>
            <span>合作工厂</span>
            <a @click="toCheckFactory()">查看</a>
          </div>
        </div>
        <div style="position: absolute; bottom: 20px; right: 20px">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="queryOpt.page.pageNumber"
            :page-size="queryOpt.page.pageCount"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <div
        v-if="list && list.length == 0"
        class="flex flex-center"
        style="text-align: center; height: 500px"
      >
        <div>
          <img
            :src="staticUrl + '/design/placeholder/meiyougengduo.png'"
            alt=""
          />
          <div style="font-size: 20px; color: #808080; padding: 15px 0">
            暂无相关数据
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧提示栏 -->
    <FloatPanel></FloatPanel>
  </div>
</template>

<script>
import _ from "lodash";
import { getDesignItems } from "@/service/mall";
import { getCats, getStyles } from "@/service/item";
import { fetchTree } from "@/util";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import FloatPanel from "../Panel.vue";

export default {
  components: {
    FloatPanel,
  },
  data() {
    return {
      catId: null,
      config: {
        styles: null, // 风格
        cats: null, //类目
      },
      opt: {
        sort_by: "", // 默认按发布时间排序，可以传price按价格排序
        sort_order: "", // 默认降序，传1表示升序
      },
      imgUrl: IMG_URL_PRE,
      staticUrl: STATIC_URL_PRE,
      queryOpt: {
        where: {
          ids: null,
          key: null,
          catIds: null,
          // priceType:[1],
          isMaster: true,
        },
        page: {
          pageNumber: 1,
          pageCount: 16,
        },
        order: [["createdAt", "desc"]],
      },
      total: 0,
      list: null,
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true,
    },
  },
  created() {
    const { query } = this.$route;
    if (query) {
      if (query.catId) {
        this.queryOpt.where.catIds = [query.catId];
      }
      if (query.search && query.search !== "") {
        this.queryOpt.where.key = query.search;
      }
    } else {
      this.refreshItems();
    }
    this.getBaseInfo();
    this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
  },
  methods: {
    goCat(item) {
      this.catId = item.key;
      this.queryOpt.where.catIds = [item.key];
      this.debounceRefreshItems();
    },
    sort(type) {
      // 排序
      if (type == "time") {
        this.opt.sort_by = "";
        this.opt.sort_order = "";
      } else if (type == "price") {
        this.opt.sort_by = "price";
        this.opt.sort_order = "";
      } else if (type == "up") {
        this.opt.sort_order = 1;
        this.queryOpt.order = [["createdAt", "asc"]];
      } else if (type == "down") {
        this.opt.sort_order = "";
        this.queryOpt.order = [["createdAt", "desc"]];
      }
      this.refreshItems();
    },
    async getBaseInfo() {
      this.config.styles = await getStyles();
      getCats().then((rst) => {
        this.config.cats = fetchTree(rst, null, {
          name: { dest: "title" },
          id: { dest: "key" },
          parent: "parentId",
        });
      });
    },
    toDetail(id) {
      this.$router.push(`/sale/mall/home/design_detail/${id}`);
    },
    goto(path) {
      this.$router.push(path);
    },
    refreshItems() {
      const _loading = this.$loading({
        fullscreen: true,
        text: "拼命加载中....",
        target: this.$refs.load,
      });
      this.list = null;
      getDesignItems(this.queryOpt)
        .then((rst) => {
          this.total = rst.count;
          this.list = rst.rows;
          console.log("getDesignItems", this.list);
          _loading.close();
        })
        .catch((err) => {
          _loading.close();
          this.$message.error(err.message);
        });
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto 40px;
}
.banner {
  height: 340px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
}
.list {
  min-height: 800px;
  padding: 30px 30px 40px 30px;
  background-color: #fff;
  position: relative;
  .item-block {
    width: 264px;
    height: 482px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(204, 204, 204, 1);
    color: #808080;
    margin: 0 28px 30px 0;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid rgba(80, 116, 238, 1);
      transform: scale(1.064);
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    > div:first-child {
      padding: 20px;
    }
    img {
      width: 224px;
      height: 224px;
    }
    .name {
      color: #292929;
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 20px;
    }
    .feature {
      color: #808080;
      font-size: 12px;
      margin-top: 10px;
    }
    .price {
      color: #ee1818;
      font-size: 20px;
      margin-top: 10px;
      span {
        color: #808080;
        font-size: 12px;
      }
    }
    .watch-count {
      color: #b3b3b3;
      font-weight: 500;
      margin-top: 10px;
    }
    .label {
      margin-top: 10px;
      display: flex;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 2px;
        padding: 0 3px;
        margin-right: 9px;
        &.color-1 {
          color: #fff;
          background-color: #f66f6a;
        }
        &.color-2 {
          color: #5074ee;
          border: 1px solid #5074ee;
        }
        &.color-3 {
          color: #2bbaae;
          border: 1px solid #2bbaae;
        }
      }
    }
    .comp-check {
      padding: 14px 20px;
      border-top: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
      position: relative;
      font-size: 12px;
      .count {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #365fe1;
        color: #365fe1;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
      }
      span {
        color: #808080;
      }
      a {
        position: absolute;
        right: 20px;
      }
    }
  }
}
</style>
